<template>
  <div class="container mx-auto px-5 flex relative py-16 align-middle justify-center">
      <h1>Em Breve</h1>
  </div>
</template>
<script>
export default {
  name: 'Map'
}
</script>
